import React from 'react'
import './footer.css'
import logo from './logo.png'
function Footer() {
  return (
    <>
    <div className='laptop'>
    <div className='footer_container'>
        <div className='footer_container1'>
            <div>
                <img src={logo}/>
            </div>
            
            <div className='footer_link_container'>
               
                    <div className='link_outer_container'>
                    <div className='footer_link_heading'>Quick Links</div>
                    <ul className='link_inner_container'>
                        <li className='link_item'>Quick links</li>
                        <li className='link_item'>Quick links</li>
                        <li className='link_item'>Quick links</li>
                    </ul>
                    </div>
                

                <div className='link_outer_container'>
                    <div className='footer_link_heading'>News</div>
                    <ul className='link_inner_container'>
                    <li className='link_item'>Quick links</li>
                    <li className='link_item'>Quick links</li>
                    <li className='link_item'>Quick links</li>
                    </ul>
                </div>
            </div>

            <div className='copywrite_notice_container'>
            © 2020 Lift Media.All right reserved
            </div>
        </div>
        <form>
            <div className='form_heading'>
                Business Enquiries 
            </div>
            <div className='input_container'>
                <input placeholder='Enter Your Name' className='form_input'/>
            </div>
            <div className='input_container'>
                <input placeholder='Enter Your Email Address' className='form_input'/>
            </div>
            <div className='input_container'>
                <input placeholder='Enter Your Phone Number' className='form_input'/>
            </div>
            <div className='input_container'>
                <textarea placeholder='Write Your Message here' className='form_input'rows="4" cols="50"/>
            </div>
            <div>
                <button className='form_btn'>
                    Submit
                </button>
            </div>
        </form>
    </div>
    </div>


    <div className='phone'>
    <div className='footer_container'>
        <div className='footer_container1'>
            <div>
                <img src={logo}/>
            </div>
            <form>
            <div className='form_heading'>
                Business Enquiries 
            </div>
            <div className='input_container'>
                <input placeholder='Enter Your Name' className='form_input'/>
            </div>
            <div className='input_container'>
                <input placeholder='Enter Your Email Address' className='form_input'/>
            </div>
            <div className='input_container'>
                <input placeholder='Enter Your Phone Number' className='form_input'/>
            </div>
            <div className='input_container'>
                <textarea placeholder='Write Your Message here' className='form_input'rows="4" cols="50"/>
            </div>
            <div>
                <button className='form_btn'>
                    Submit
                </button>
            </div>
        </form>
            
            <div className='footer_link_container'>
               
                    <div className='link_outer_container'>
                    <div className='footer_link_heading'>Quick Links</div>
                    <ul className='link_inner_container'>
                        <li className='link_item'>Quick links</li>
                        <li className='link_item'>Quick links</li>
                        <li className='link_item'>Quick links</li>
                    </ul>
                    </div>
                    
                <div className='link_outer_container'>
                    <div className='footer_link_heading'>News</div>
                    <ul className='link_inner_container'>
                    <li className='link_item'>Quick links</li>
                    <li className='link_item'>Quick links</li>
                    <li className='link_item'>Quick links</li>
                    </ul>
                </div>
            </div>

            <div className='copywrite_notice_container'>
            © 2020 Lift Media.All right reserved
            </div>
        </div>
        
    </div>
    </div>
    </>
  )
}

export default Footer