import React, { Component , useEffect, useState} from "react";
import Navbar from "./Navbar/Navbar";
import OneWomen from "./OneWomen/OneWomen";
import Podcasts from "./Podcasts/Podcasts";
import main from "./main.mp4";
import "./style.css";
import BigCard from "./BigCard/BigCard";
import Button from "./Button/Button";
import Blog_Thoughts from "./Blog&Thoughts/Blog_Thoughts";
import Quotes from "./Quotes/Quotes";
import PressRelease from "./PressRelease/PressRelease";
import ShareCard from "./Common/ShareCard";
import Contact from "./Contact/Contact";
import Footer from "./Footer/Footer";
import Connect from "./Connect/Connect";
import Example from './Example'
import Loading from "./Loading";
const App1=()=>{
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleLoad = () => {
      console.log("gh");
      setLoading(false);
    };

    setTimeout(handleLoad,1500)
  }, []);


  console.log(loading)
    return (
      <>
     { loading?<Loading/>:<div style={{overflowX:'none'}}>
        <div className="main-container">
          <video autoPlay muted loop id="myVideo">
            <source src={main} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <Navbar />
          <div className="bg-linearGradient">
            <div className="heroText-container">
              <div className="coach">Coach</div>
              <div className="shobha-rana">
                Shobha
                <br />
                Rana
              </div>
              <div className="personality">
                Holistic Wellness & Mindfulness, Meditation<br></br> Coach
                Founder, <span className="yogini"></span> The Yogini Square
              </div>
            </div>
          </div>
        </div>
       
        <OneWomen />
        <Podcasts />
        <BigCard/>
        <Blog_Thoughts/>
        <Quotes/>
        <PressRelease/>
        
        <Contact/>
        <Connect/>
        {/* <Example/> */}
        <Footer/>
      </div>}
      </>
    );
  
}

export default App1;
