import logo from './logo.svg';
import './App.css';
import App1 from './Components/App';

function App() {
  return (
    <App1/>
  );
}

export default App;
