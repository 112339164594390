import React, { useEffect, useRef, useState } from "react";
import Lives from "./Lives";
import "./Style.css";
import host from "./blue.png";
import speaker from "./greenCicle.png";
import teacher from "./red.png";
import entrepreneur from "./skyblueCircle.png";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import onewomen from './onewomen.png'
// import required modules
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';

import Slide from "./Slide";
function OneWomen() {
  const ref = useRef(null);
  const [inView, setInView] = useState(false);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  const handleIntersection = (entries, observer) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        setInView(true);
        observer.unobserve(entry.target);
      }
    });
  };
  console.log(inView)

  return (
    <div className={`onewomen-main-container`} >
      <div className="onewomen-heading ">
        One Woman Many <span className="lives">Lives</span>
      </div>
      <div   className={`lives-container laptop`} ref={ref}>
        <Lives
          className={`entrepreneur-box ${inView ? "entrepreneur-in-view" : ""}`}
          heading="Entrepreneur"
          text="Esteemed Yoga Trainer with an experience of 10 years in Training Yoga and meditation"
          image={entrepreneur}
        />

        <Lives
          className={`host-box ${inView?"host-in-view":""}`}
          heading="Host & Emcee"
          text="Spoken at over 1000 Events and these data will appear here"
          image={host}
        />

        <Lives
          className={`speaker-box ${inView?"speaker-in-view":""}`}
          heading="Speaker & Coach"
          text="Spoken at over 1000 Events and these data will appear here"
          image={speaker}
        />

        <div className="teacher-box">
          <div className="teacher-texts">
            <p className="lives-heading">Teacher</p>
            <p className="lives-para">
              Esteemed Yoga Trainer with an experience of 10 years in Training
              Yoga and meditation
            </p>
          </div>
          <img src={teacher} alt="Teacher" className="imageClass" />
        </div>
      </div>

      <div className="lives-container phone">
        <div className="onewomen_image_container">
          <img src={onewomen}/>
        </div>
        <div className="lives_carousel">
        <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide><Slide heading="Entrepreneur" para1="Esteemed Yoga Trainer with an experience of" para2="10 years in Training Yoga and meditation"/></SwiperSlide>
        <SwiperSlide><Slide heading="Host & Emcee" para1="Esteemed Yoga Trainer with an experience of" para2="10 years in Training Yoga and meditation"/></SwiperSlide>
        <SwiperSlide><Slide heading="Teacher" para1="Esteemed Yoga Trainer with an experience of" para2="10 years in Training Yoga and meditation"/></SwiperSlide>
        <SwiperSlide><Slide heading="Speaker & Coach" para1="Esteemed Yoga Trainer with an experience of" para2="10 years in Training Yoga and meditation"/></SwiperSlide>
      </Swiper>
        </div>
      </div>
    </div>
  );
}

export default OneWomen;
