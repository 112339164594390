import React from 'react'
import './blog_thoughts.css'
import Categories from '../Common/Categories'
import pic1 from './CANehaDutta.png';
import pic2 from './ColRajeevBharwan.png';
import pic3 from './PrincySaini.png'
function Blog_Thoughts() {
  return (
    <div >
      <div className='Blog_thought_heading'>
      <Categories subHeading="Blogs & Thoughts" heading="Blogs That speak to you"/>
      </div>
      <div className='gallery_container'>

        <div className='gallery_container_1'>

          
        {/* <div style={{backgroundImage:`url(${pic1})`, height:'86vh', backgroundPosition:'center', backgroundRepeat:'no-repeat', backgroundSize:'100% 100%'}} >
          </div> */}
          {/* <div> */}
          <img src={pic1} style={{width:'100%', height:'100%'}} />
          {/* </div> */}
          
        </div>

        <div className='gallery_container_2'>
          <div>
            <img src={pic2} style={{width:'100%', height:'100%'}}/>
          </div>
          
          <div>
            <img src={pic3} style={{width:'100%', height:'100%'}}/>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Blog_Thoughts