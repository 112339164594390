import React, { useEffect, useState } from "react";
import logo from "./Logo.png";
import "./Navbar.css";
import hamburger from './Hamburger_MD.svg'
import logophone from './Logophone.svg'
function Navbar() {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolling(true);
      } else {
        setScrolling(false);
        
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const navbarStyle = {
    backgroundColor: scrolling ? "black" : "transparent",
    height: scrolling ? "104px" : "204px",
    top:scrolling ? "0px" : "-0.9px",
  };
  const navbarStylephone = {
    backgroundColor: scrolling ? "black" : "transparent",
    height: scrolling ? "104px" : "104px",
    top:scrolling ? "0px" : "-0.9px",
  };

  return (
    <>
    <div className="laptop">
    <nav className="nav" style={navbarStyle}>
      <li className="logo-container">
        <img src={logo} />
      </li>
      <li className="nav-item-container">
        <li className="nav-item-text">About</li>
        <li className="nav-item-text">Podcast</li>
        <li className="nav-item-text">Quotes</li>
        <li className="nav-item-text">Blogs</li>
        <li className="nav-item-text">Courses</li>
        <li className="nav-item-text">Media + Awards</li>

        <ul className="btn-container">
          <li className="btn gradient-border-mask">Quizzes</li>
          <li className="btn session-btn">1:1 Session</li>
        </ul>
      </li>
    </nav>
    </div>
    <div className="phone">
    <nav className="nav" style={navbarStylephone}>
      <li className="logo-container-phone">
        <img src={logophone} />
      </li>
      <li className="nav-item-container-phone">
        <img src={hamburger} />
      </li>
      {/* <li className="nav-item-container">
        <li className="nav-item-text">About</li>
        <li className="nav-item-text">Podcast</li>
        <li className="nav-item-text">Quotes</li>
        <li className="nav-item-text">Blogs</li>
        <li className="nav-item-text">Courses</li>
        <li className="nav-item-text">Media + Awards</li>

        <ul className="btn-container">
          <li className="btn gradient-border-mask">Quizzes</li>
          <li className="btn session-btn">1:1 Session</li>
        </ul>
      </li> */}
    </nav>
    </div>
    </>
  );
}

export default Navbar;
