import React from 'react'
import Button from '../Button/Button'
import coma from './Group.svg'
import backComa from './backwardComa.svg'
import arrow from '../Common/Arrow_Left_LG.svg'
function ShareCard() {
  return (
    <div className='shared_card_container'>
        <div className='Quote_heading'>
            <div className='left_coma'><img src={coma}/></div>
            When there is a will There is a way 
            <div className='right_coma'><img src={backComa}/></div>
        </div>
        <div className='Quote_btn'>
        <div className='book_now_btn_container'>
                <div className='book_now_btn'>
                   Share
                   
                  <img src={arrow} id="left_arrow"/>
                
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default ShareCard