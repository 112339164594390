import React from 'react'
import './Style.css'
import Categories from '../Common/Categories'
import Blog_Card from '../Common/Blog_Card'
function PressRelease() {
  return (
    <div>
        <div className='Press_heading'>
            <Categories subHeading="Press Releases" heading="Press Releases"/>
        </div>
        <div>
          <Blog_Card/>
          <Blog_Card/>
          <Blog_Card/>
        </div>
    </div>
  )
}

export default PressRelease