import React from 'react'
import image from './background.png'
import Button from '../Button/Button'
function Blog_Card() {
  return (
    <div className='blog_card_container'>
        <div className='blog_image_container'>
            <img src={image}/>
        </div>

        <div className='content_container'>
            <div className='blog_heading'>
            Blog Heading 01
            </div>
            <div className='blog_caption'>
            Uncover a deeper understanding of yourself and discover ways to elevate your spiritual growth.
            </div>
            <div >
                <Button/>
            </div>
        </div>

    </div>
  )
}

export default Blog_Card