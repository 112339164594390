import React from 'react'
import './connect.css'
import image from './bg.png'
import phone from './assets/schedulephone.png'
import Button from '../Button/Button'
function ScheduleMeeting() {


  return (
    <div className='outerContainer_schedule'>
        <div className='innerContainer_schedule'>
        <div className='bg_image_container'>
            <img src={image} className='laptop_tablet' />
            <img src={phone} className='onlyPhone' />
        </div>

        <div className='scheduleMetting_content'>
            <div className='schedule_meeting_heading'>
            Schedule a meeting on <br></br>Calendly
            </div>
            <div>
                <Button/>
            </div>
        </div>
        </div>
    </div>
  )
}

export default ScheduleMeeting