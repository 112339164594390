import React from 'react'
import Categories from '../Common/Categories'
import ScheduleMeeting from './ScheduleMeeting'
import SocialMedia from './SocialMedia'
import NewsLetter from './NewsLetter'
function Connect() {
  return (
    <div>
       <div className='connect_container'>
            <Categories heading="Connect" subHeading=""/>
        </div>
        <div>
            <ScheduleMeeting/>
            <div className='connect_container1'>
              <SocialMedia/>
              <NewsLetter/>
            </div>
        </div>
        
    </div>
  )
}

export default Connect