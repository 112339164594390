import React from 'react'

function Slide({heading, para1, para2}) {
  return (
    <div className='slide_container'>
        <div className='slide_heading' >
            {heading}
        </div>
        <div className='slide_para'>
        {para1}
        <span className='slide_bold_para'>{para2}</span>
        </div>
    </div>
  )
}

export default Slide