import React from 'react'
import logo from './Navbar/Logo.png'
function Loading() {
  return (
    <div style={{overflowY:'hidden'}}>
    <div style={{height:'100vh', backgroundColor:'black', color:'white', width:'100vw', display:'flex', justifyContent:'center', alignItems:'center'}}>
       <img src={logo} />
    </div>
    </div>
  )
}

export default Loading