import React from 'react'
import circle from './bg_icons.png'
import circle2 from './circle2.png'
import youtube from './assets/YouTube.svg';
import insta from './assets/Instagram.svg'
import twitter from './assets/Twitter.svg'
import facebook from './assets/Facebook.svg'
function SocialMedia() {
  return (
    <div className='outerContainer_socialMedia'>
        <div className='innerContainer_socialMedia'>
            <div className='social_media_heading'>
            Connect on <span className='social_media_span'>Social Media</span>
            </div>
            <div className='social_media_caption'>
                Stay updated on your timelines
            </div>
            <div className='circle_bg_container'>
                <img src={circle} className='circle_bg circle1'/>
                <img src={circle2} className='circle_bg circle2'/>
                <img src={circle2} className='circle_bg circle3'/>
                <img src={circle} className='circle_bg circle4'/>
                <img src={circle2} className='circle_bg circle5'/>
            </div>
            <div className='social_icons_container'>
               <img src={youtube} alt='youtube' className='social_icons'/>
               <img src={insta} alt='insta' className='social_icons'/>
               <img src={twitter} alt='twitter' className='social_icons'/>
               <img src={facebook} alt='facebook' className='social_icons'/>
            </div>
        </div>
    </div>
  )
}

export default SocialMedia