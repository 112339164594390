import React from 'react'
import Categories from '../Common/Categories'
import './Quotes.css'
import ShareCard from '../Common/ShareCard'
function Quotes() {
  return (
    <div >
        <div className='quotes_heading'>
            <Categories subHeading="Quotes" heading="Shareable Quotes"/>
        </div>
        <div className='swiper-container '>
          <div className='slide-1'><ShareCard/></div>
          <div className='slides'><ShareCard/></div>
          <div className='slides'><ShareCard/></div>
          <div className='slides'><ShareCard/></div>
          <div className='slides'><ShareCard/></div>
          <div className='slides'><ShareCard/></div>
        </div>
    </div>
  )
}

export default Quotes