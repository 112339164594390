import React from 'react'
import Categories from '../Common/Categories'
import BookingCard from '../Common/BookingCard'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
// import required modules
import './contact.css'
import { Navigation, Pagination, Mousewheel, Keyboard } from 'swiper/modules';
function Contact() {
  return (
    <div>
        <div className='contactUsHeading'>
            <Categories subHeading="Contact us" heading="Bookings"/>
        </div>
        <div className='laptop'>
        <div className='booking_cards_container'>
        <BookingCard heading="For Coaching" caption1a=" Esteemed Yoga Trainer with an experience of" caption1b="10 years in Training Yoga and meditation" navigate=""/>
        <BookingCard  heading="For Public Speaking" caption1a=" Esteemed Yoga Trainer with an experience of" caption1b="10 years in Training Yoga and meditation" navigate=""/>
        <BookingCard  heading="Shobha’s Course" caption1a=" Esteemed Yoga Trainer with an experience of" caption1b="10 years in Training Yoga and meditation" navigate=""/>
        <BookingCard  heading="Speaker & Coach" caption1a=" Esteemed Yoga Trainer with an experience of" caption1b="10 years in Training Yoga and meditation" navigate=""/>
        </div>
        </div>
        <div className='phone'>
        <div className='booking_cards_container'>
        <Swiper
        cssMode={true}
        navigation={true}
        pagination={true}
        mousewheel={true}
        keyboard={true}
        modules={[Navigation, Pagination, Mousewheel, Keyboard]}
        className="mySwiper"
      >
        <SwiperSlide> 
          <BookingCard heading="For Coaching" caption1a=" Esteemed Yoga Trainer with an experience of" caption1b="10 years in Training Yoga and meditation" navigate=""/></SwiperSlide>
        <SwiperSlide>Slide 2</SwiperSlide>
        <SwiperSlide>Slide 3</SwiperSlide>
        <SwiperSlide>Slide 4</SwiperSlide>
        <SwiperSlide>Slide 5</SwiperSlide>
        <SwiperSlide>Slide 6</SwiperSlide>
        <SwiperSlide>Slide 7</SwiperSlide>
        <SwiperSlide>Slide 8</SwiperSlide>
        <SwiperSlide>Slide 9</SwiperSlide>
      </Swiper>
        </div>
        </div>
    </div>
  )
}

export default Contact