import React, { useEffect, useRef } from "react";
import "./Style.css";

function Lives({ heading, text, image, className }) {
  const elementRef = useRef(null);

  useEffect(() => {
    const handleIntersection = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = 1;
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, { threshold: 0.5 });

    if (elementRef.current) {
      observer.observe(elementRef.current);
    }

    // Cleanup the observer when the component unmounts
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []); // Empty dependency array ensures the effect runs only once


  return (
    <div className={`${className}`} ref={elementRef}>
      <div className="lives-texts">
        <p className="lives-heading">{heading}</p>
        <p className="lives-para">{text}</p>
      </div>
      <div className="imageParentDiv">
      <img src={image} alt={heading} className="imageClass" />
      </div>
    </div>
  );
}

export default Lives;
