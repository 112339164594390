import React, { useEffect, useState } from "react";
import Categories from "../Common/Categories";
import arrow from "./Arrow_Left_LG.svg";
import "./Style.css";
import podcasts_slides from "./podcasts_img.png";
import Loading from "../Loading";

function Podcasts() {
  
  return (
    <>
    <div className="podcasts-container" >
      <div className="podcast_heading_container">
        <Categories
          subHeading="Podcasts"
          heading="Podcast with over 100 Guests and 100+ Episodes on Mental Health and Social wellness"
        />
      </div>
      <div class="swiper-container">
        <div className="slide-1">  <iframe className="slide-iframe"  src="https://www.youtube.com/embed/ahTHcIv7_WU?si=0jjDMnLMaO1cbl2J" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
      <div className="slides"><iframe className="slide-iframe" src="https://www.youtube.com/embed/owG0sPn9gHc?si=JBpL-b8sSSfETxv5" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
      <div className="slides"><iframe className="slide-iframe" src="https://www.youtube.com/embed/-XwU-sT2IkI?si=mmP7EQ3M3EcLkJzH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
      <div className="slides"><iframe className="slide-iframe" src="https://www.youtube.com/embed/jJMkZoz6BwE?si=ZGYJ7OXrcUuhT-yz" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
      <div className="slides"><iframe className="slide-iframe" src="https://www.youtube.com/embed/Ps5KtJYVA0k?si=UnE9Isaqsb2rx8hk" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></div>
      </div>


      <div>
        <div className="youtubebtn" onClick={()=>{
          window.open("https://www.youtube.com/@iamshobharana")
        }}>
          Watch on Youtube
          <img src={arrow} />
        </div>
      </div>
    </div>
    </>
  );
}

export default Podcasts;
