import React from 'react'
import './connect.css'
function NewsLetter() {
  return (
    <div className='outerContainer_newsletter'>
        <div className='innerContainer_newsletter'>
            <div className='newsletter_heading'>
            Subscribe to my <span className='newsletter'>Newsletter</span>
            </div>

            <div className='newsletter_caption'>
            Recieve Weekly newsletter about life, meditation 
            </div>

            <form className='newsletter_form'>
                <div className='email_input'>
                    <input placeholder='Enter Your Email' />
                </div>
                <div>
                    <button className='subscribe_btn'>
                    Subscribe 
                    </button>
                </div>
            </form>
        </div>
    </div>
  )
}

export default NewsLetter