import React from 'react'
import image from './booking.png'
import './booking_card.css'
import image4 from './booking_card_image1.png'
function BookingCard({ heading, caption1a, caption1b, navigate, type}) {
  return (
    <div className='booking_card_container'>
        <div className='booking_card_image_container'>
            {heading==="For Coaching" &&<img src={image}/>}
            {heading==="For Public Speaking" &&<img src={image}/>}
            {heading==="Shobha’s Course" &&<img src={image}/>}
            {heading==="Speaker & Coach" &&<img src={image4}/>}
        </div>
        <div>
            <div className='booking_card_heading'>
            {heading}
            </div>
            <div className='booking_card_caption'>
           {caption1a}
            <span className='caption_bold'>{caption1b}</span>
            </div>
            <div className='book_now_btn_container slider_btn'>
                <div className='book_now_btn'>
                    Book Now
                </div>
            </div>
        </div>
    </div>
  )
}

export default BookingCard