import React from "react";
import arrow from "./Arrow_Left_LG.png";

function Categories({ subHeading, heading }) {
  return (
    <div className="categories-container" style={{borderBottom:subHeading==="Podcasts"&&'0px'}}>
      <div className="categories-subHeading">
        {subHeading}
        {subHeading && <img src={arrow} alt="" style={{ marginLeft: "5px" }} />}
      </div>
      <div className="categories-heading">{heading}</div>
    </div>
  );
}

export default Categories;
