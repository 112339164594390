import React from 'react'
import './style.css'
import Button from '../Button/Button'
function BigCard() {
  return (
    <div className='outerContainer'>
        <div className='innerContainer'>
            <div className='Explore_innerself_heading'>
            Explore your inner self and enhance your spiritual well-being by taking this insightful quiz. 
            </div>
            <div className='uncover-caption'>
            Uncover a deeper understanding of yourself and discover ways to elevate your spiritual growth.
            </div>

            <div>
              <Button/>
            </div>
        </div>
    </div>
  )
}

export default BigCard