import React from 'react';
import './style.css';
import arrow from '../Common/Arrow_Left_LG.png'
function Button() {
  return (
    <div className='container'>
      <div className='btn-title'>Take Quiz</div>
      <div className='arrow-btn'><img src={arrow}/></div>
    </div>
  );
}

export default Button;
